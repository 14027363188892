import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

const httpOptions = {
	headers: new HttpHeaders({ 'Accept': 'application/json' })
};

@Injectable({
	providedIn: 'root'
})
export class BaseService {

	constructor(protected http: HttpClient) {}

	/**
	 * Generic GET request
	 * @param url API endpoint
	 * @param requestBody Optional query parameters
	 * @returns Observable of the generic type T
	 */
	protected get<T, B = undefined>(url: string, requestBody?: B, responseType?: 'json'): Observable<T> {
		const options = {
			headers: httpOptions.headers,
			params: new HttpParams({ fromObject: requestBody || {} }),
			responseType: responseType || 'json',
			withCredentials: true
		};
		return this.http.get<T>(url, options).pipe(
			catchError((error) => this.handleError(error))
		);
	}

	/**
	 * Generic POST request
	 * @param url API endpoint
	 * @param requestBody Request payload
	 * @returns Observable of the generic type T
	 */
	protected post<T, B>(url: string, requestBody?: B, responseType?: 'json'): Observable<T> {
		const options = {
			headers: httpOptions.headers,
			responseType: responseType || 'json',
			withCredentials: true
		};
		return this.http.post<T>(url, requestBody, options).pipe(
			catchError((error) => this.handleError(error))
		);
	}

	/**
	 * Generic PUT request
	 * @param url API endpoint
	 * @param requestBody Request payload
	 * @returns Observable of the generic type T
	 */
	protected put<T, B>(url: string, requestBody: B): Observable<T> {
		return this.http.put<T>(url, requestBody, { headers: httpOptions.headers }).pipe(
			catchError((error) => this.handleError(error))
		);
	}

	/**
	 * Generic DELETE request
	 * @param url API endpoint
	 * @param requestBody Optional query parameters
	 * @returns Observable of the generic type T
	 */
	protected delete<T, B>(url: string, requestBody?: B): Observable<T> {
		const options = {
			params: new HttpParams({ fromObject: requestBody || {} }),
			headers: httpOptions.headers
		};
		return this.http.delete<T>(url, options).pipe(
			catchError((error) => this.handleError(error))
		);
	}

	/**
	 * Error handler
	 * @param error HTTP error response
	 * @returns Observable that throws the error
	 */
	protected handleError(error: HttpErrorResponse): Observable<never> {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code and error details.
			console.error('Server Side Error: ', {
				status: error.status,
				message: error.error?.message || error.message,
				details: error.error
			});
			
		}
	
		return throwError(() => error.error || error);
	}
	
}